import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactStyles from "./contact.module.css"
import Footer from "../components/Footer"
import JobCard from "../components/JobCard"
import jobs from "../data/jobs"
import JobsStyles from "./Jobs.module.css"
const Jobs = () => {
  const handleApplyJob = event => {
    event.preventDefault()
  }

  return (
    <Layout>
      <SEO title="Jobs" />
      <h1 className={"hidden"}>Jobs</h1>
      <div className="row mt-90 ">
        <div className="col-12 col-sm mb-30">
          <div className={ContactStyles.contact_title}> Jobs</div>
        </div>
      </div>
      <div className={JobsStyles.container}>
        {jobs.map(job => (
          <JobCard job={job} key={"jobs" + job.id} />
        ))}
      </div>
      <Footer />
    </Layout>
  )
}

export default Jobs
