export default [
  {
    id: 1,
    title: "Creative Content Manager Arıyoruz!",
    content:
      " \
      <p> Deneyim odaklı bir çözüm ajansı olan <b>Manastır</b> olarak, iş ortaklarımıza danışmanlık, etkinlik ve dijital ürün ihtiyaçları için sıra dışı projeler\
        hayata geçiriyor, günden güne çoğalarak ekibimizi büyütüyoruz. 🚀\
      </p>\
      <p><b>İstanbul</b> ofisimizdeki ekibimiz için <b>Creative Content Manager</b> arıyoruz.</p> \
      <p><b><i>Aranan Özellikler</i></b></p>\
      <ul>\
        <li>Üniversitelerin ilgili bölümlerinden lisans veya yüksek lisans derecesi,</li>\
        <li>Benzer sorumlulukların yerine getirildiği pozisyonlarda minimum 3-4 yıl ajans tecrübesi,</li>\
        <li>Güçlü metin yazarlığı kabiliyetleri,</li>\
        <li>Araştırmacı, yeniliklere ve değişime açık, yaratıcı düşünme becerilerine sahip, trendleri takip eden,</li>\
      </ul>\
      <p><b><i>İş Tanımı</i></b></p>\
      <ul>\
        <li>İçerik stratejileri geliştirmek,</li>\
        <li>Yaratıcı bir hayal gücüyle proje, içerik geliştirme ve yürütme süreçlerine katkıda bulunmak,</li>\
        <li>Markalama, pazarlama iletişimi, proje geliştirme fikirleri üretmek üzere tasarım ve marka ekibimizle koordineli çalışmak,</li>\
        <li>Projelerimizin strateji geliştirme süreçlerinde içerik katkısı sunmak.</li>\
        <li>Gerektiğinde diğer ekiplerimize fikir ve ilham sağlamak.</li>\
      </ul>\
      <p>İlgilenen adayların özgeçmişlerini <i>‘’merhaba@manastir.net’’</i> adresine bekliyoruz.</p> \
    ",
    email: "merhaba@manastir.net",
    location: "İstanbul",
    summary:
      "Deneyim odaklı bir çözüm ajansı olan <span className='text-bold'>Manastır</span> olarak, iş ortaklarımıza danışmanlık, etkinlik ve dijital ürün ihtiyaçları için sıra dışı projeler hayata geçiriyor, günden güne çoğalarak ekibimizi büyütüyoruz.",
    typeOfWork: "Tam Zamanlı",
    experince: "Deneyimli",
    releaseDate: "14/09/2022",
    mailSubject: "Creative Content Manager - İş Başvurusu",
  },
  {
    id: 2,
    title: "Experience Manager Arıyoruz!",
    content:
      " \
      <p> Deneyim odaklı bir çözüm ajansı olan <b>Manastır</b> olarak, iş ortaklarımıza danışmanlık,\
        etkinlik ve dijital ürün ihtiyaçları için sıra dışı projeler hayata geçiriyor, günden güne çoğalarak ekibimizi büyütüyoruz. 🚀\
      </p>\
      <p><b>İstanbul</b> ofisimizdeki ekibimiz için <b>Experience Manager</b> arıyoruz.</p> \
      <p><b><i>Aranan Özellikler</i></b></p>\
      <ul>\
        <li>Sürekli öğrenme hevesi olan ve enerjisi yüksek,</li>\
        <li>Üniversitelerin ilgili bölümlerinden Lisans veya Yüksek Lisans mezunu (tercihen Endüstri Mühendisliği mezunu)</li>\
        <li>En az 4 yıl ajans tecrübesine sahip,</li>\
        <li>Deneyim tasarımı, etkinlik tasarımı konularına ilgili,</li>\
        <li>Karmaşık ve hızlı değişen koşullarda etkin bir şekilde çalışabilecek, </li>\
        <li>Analitik düşünme becerisi ve görsel sunum kabiliyeti yüksek,</li>\
        <li>Sunum görsel tasarımı hazırlamakta sorun yaşamayan ve etkili sunum yapabilen,</li>\
        <li>İyi derecede İngilizce konuşma ve yazma becerilerine sahip,</li>\
        <li>Etkinlik deneyimi, iletişimi, konuşmacı iletişimi konularında tecrübeli,</li>\
        <li>Etkinlik dünyası trendlerine hakim ve onları yakından takip eden,</li>\
      </ul>\
      <p><b><i>İş Tanımı</i></b></p>\
      <ul>\
        <li>Markaların ihtiyaçlarını analiz ederek, yaratıcı etkinlikler ve deneyim stratejileri üretmeye katkı sağlar.</li>\
        <li>Etkinlik planlarını geliştirmeye ortak olur ve etkinlik yönetiminde aktif rol alır.</li>\
        <li>Etkinlik konseptleri, konuşmacıları, mekânları, web siteleri ve pazarlamaları için içerik oluşturur, bilgileri güncel tutar ve ekip arkadaşlarına uygun bir aktarım sağlayarak bütünsel yönetimde sorumluluk alır.</li>\
        <li>Etkinlik konsepti yaratım sürecinde kreatif ve iletişim ekipleri ile birlikte uyumlu çalışır.</li>\
        <li>Etkinlikler için trend analizi yaparak farklılaşma noktalarını tespit eder, uygulanabilir olanları raporlar.</li>\
        <li>Bütünsel deneyim modeline bağlı geliştirmeler ve iş birliği için tedarikçi araştırmaları yaparak iletişim ve anlaşma yönetimi sağlar.</li>\
      </ul>\
      <p>İlgilenen adayların özgeçmişlerini <i>‘’merhaba@manastir.net’’</i> adresine bekliyoruz.</p> \
    ",
    email: "merhaba@manastir.net",
    location: "İstanbul",
    typeOfWork: "Tam Zamanlı",
    experince: "Deneyimli",
    releaseDate: "14/09/2022",
    mailSubject: "Creative Content Manager - İş Başvurusu",
  },
  {
    id: 3,
    title: "New Business Manager Arıyoruz!",
    content:
      " \
      <p> Deneyim odaklı bir çözüm ajansı olan <b>Manastır</b> olarak, iş ortaklarımıza danışmanlık, etkinlik ve dijital ürün ihtiyaçları için sıra dışı projeler\
        hayata geçiriyor, günden güne çoğalarak ekibimizi büyütüyoruz. 🚀\
      </p>\
      <p><b>İstanbul</b> ve <b>Ankara</b> ofisimizdeki ekibimiz için <b>New Business Manager</b> arıyoruz.</p> \
      <p><b><i>Aranan Özellikler</i></b></p>\
      <ul>\
        <li>Üniversitelerin ilgili alanlarından lisans veya yüksek lisans derecesine sahip,</li>\
        <li>Büyüme hedeflerimize uygun olarak yeni iş stratejileri geliştirebilecek,</li>\
        <li>İyi derecede iletişim becerilerine sahip ve ikna kabiliyeti yüksek,</li>\
        <li>Araştırmacı, yeniliklere ve değişime açık, yaratıcı düşünme becerilerine sahip,</li>\
        <li>Performans değerlendirmesi ve raporlama yapabilecek, </li>\
      </ul>\
      <p><b><i>İş Tanımı</i></b></p>\
      <ul>\
        <li>Potansiyel müşterilerle yeni iş ilişkileri oluşturmak,</li>\
        <li>Yeni iş stratejileri geliştirmek ve uygulamak,</li>\
        <li>Mevcut ve yeni projelerin iş akışına uygun bir şekilde yürütülmesini sağlamak,</li>\
        <li>Müşteri devamlılığı ve bağlılığının sağlanmasına yönelik stratejileri hayata geçirmek, koordinasyon ve takibini yapmak,</li>\
        <li>Müşteri ihtiyaç ve beklentilerine yönelik etkin müşteri iletişimleri tasarlamak.</li>\
      </ul>\
      <p>İlgilenen adayların özgeçmişlerini <i>‘’merhaba@manastir.net’’</i> adresine bekliyoruz.</p> \
    ",
    email: "merhaba@manastir.net",
    location: "İstanbul - Ankara",
    typeOfWork: "Tam Zamanlı",
    experince: "Deneyimli",
    releaseDate: "14/09/2022",
    mailSubject: "New Business Manager - İş Başvurusu",
  },
  {
    id: 4,
    title: "Senior Event and Project Designer Arıyoruz!",
    content:
      " \
      <p> Deneyim odaklı bir çözüm ajansı olan <b>Manastır</b> olarak, iş ortaklarımıza danışmanlık, etkinlik ve dijital ürün ihtiyaçları için sıra dışı projeler\
        hayata geçiriyor, günden güne çoğalarak ekibimizi büyütüyoruz. 🚀\
      </p>\
      <p><b>Pera</b> ofisimizdeki ekibimiz için <b>Senior Event and Project Designer</b> arıyoruz.</p> \
      <p><b><i>Aranan Özellikler</i></b></p>\
      <ul>\
        <li>Üniversitelerin mühendislik fakültelerinden mezun,</li>\
        <li>Marka yada iletişim ajanslarında en az 3 yıl tecrübe sahibi,</li>\
        <li>Microsoft Office programlarına hakim,</li>\
        <li>Planlama, organizasyon ve sunum becerisi yüksek,</li>\
        <li>Etkinlik deneyimi, iletişimi, konuşmacı konularında tecrübeli,</li>\
        <li>Etkinlik sektörüne hakim ve daha önce bu sektörde çalışma tecrübesi olan, </li>\
        <li>Etkin zaman yönetimi ile yoğun çalışma temposuna planlı şekilde ayak uydurabilecek,</li>\
        <li>Enerjisi ve kişisel motivasyonu yüksek, sorumluluk sahibi, inisiyatif alabilen, proaktif ve disiplinli,</li>\
        <li>İyi derecede İngilizce bilgisi olan,</li>\
      </ul>\
      <p><b><i>İş Tanımı</i></b></p>\
      <ul>\
        <li>Müşteri beklentileri doğrultusunda yaratıcı etkinlikler ve deneyim stratejileri üretmeye katkı sağlamak,</li>\
        <li>Etkinlik konsepti oluşturma sürecinde kreatif ve iletişim ekipleri ile birlikte çalışmak,</li>\
        <li>Etkinliklerin ve projelerin risk, bütçe ve zaman yönetimini gerçekleştirmek,</li>\
        <li>Sektör hakkında araştırmalarda bulunarak trend ve rakip analizlerini yapmak,</li>\
        <li>Yenilikçi fikirler geliştirmek.</li>\
      </ul>\
      <p>İlgilenen adayların özgeçmişlerini <i>‘’merhaba@manastir.net’’</i> adresine bekliyoruz.</p> \
    ",
    email: "merhaba@manastir.net",
    location: "İstanbul",
    typeOfWork: "Tam Zamanlı",
    experince: "Deneyimli",
    releaseDate: "14/09/2022",
    mailSubject: "Senior Event and Project Designer - İş Başvurusu",
  },
  {
    id: 5,
    title: "Sosyal Medya Yöneticisi Arıyoruz!",
    content:
      " \
      <p> Deneyim odaklı bir çözüm ajansı olan <b>Manastır</b> olarak, iş ortaklarımıza danışmanlık, etkinlik ve dijital ürün ihtiyaçları için sıra dışı projeler\
        hayata geçiriyor, günden güne çoğalarak ekibimizi büyütüyoruz. 🚀\
      </p>\
      <p><b>Pera</b> ofisimizdeki ekibimiz için <b>Sosyal Medya Yöneticisi</b> arıyoruz.</p> \
      <p><b><i>Aranan Özellikler</i></b></p>\
      <ul>\
        <li>En az 3 yıl ajans tecrübesi olan,</li>\
        <li>Sosyal medya içerik ve kampanya üretme süreçlerinde yer almış,</li>\
        <li>Sosyal medya ve mecralarının dinamiklerini bilen ve takip eden,</li>\
        <li>Raporlama ve sunum tekniklerine hâkim,</li>\
        <li>Raporlama sonuçlarına göre yeni sosyal medya stratejileri üretebilecek,</li>\
        <li>İçerik üretme konusunda heyecan duyan ve yenilikçi yaklaşan,</li>\
        <li>Yoğun çalışma temposuna ayak uydurabilecek,</li>\
        <li>Stratejik planlama süreçlerine dahil olabilecek,</li>\
        <li>Performans değerlendirmesi ve raporlama yapabilecek,</li>\
      </ul>\
      <p><b><i>İş Tanımı</i></b></p>\
      <ul>\
        <li>Ajansımızın yürüttüğü projeler için sektör analizleri yaparak sosyal medya stratejisi oluşturmak ve uygulamak,</li>\
        <li>Projeler kapsamında müşterilerimizin hedef kitlesine hitap eden, online ve offline ortamlara uygun ilgi çekici içerik hazırlamak, içerik kontrollerini sağlamak ve yayınlamak</li>\
        <li>Projelerin iş akışına uygun bir şekilde yürütülmesini sağlamak,</li>\
        <li>İçerik etkinliğine ilişkin analiz ve raporlama yapmak</li>\
      </ul>\
      <p>İlgilenen adayların özgeçmişlerini <i>‘’merhaba@manastir.net’’</i> adresine bekliyoruz.</p> \
    ",
    email: "merhaba@manastir.net",
    location: "İstanbul",
    typeOfWork: "Tam Zamanlı",
    experince: "Deneyimli",
    releaseDate: "14/09/2022",
    mailSubject: "Sosyal Medya Yöneticisi - İş Başvurusu",
  },
  {
    id: 6,
    title: "Grafik Tasarımcı Arıyoruz!",
    content:
      " \
      <p>Deneyim odaklı bir çözüm ajansı olan <b>Manastır</b> olarak, iş ortaklarımıza danışmanlık, etkinlik ve dijital ürün ihtiyaçları için sıra dışı projeler\
        hayata geçiriyor, günden güne çoğalarak ekibimizi büyütüyoruz. 🚀\
      </p>\
      <p><b>Ankara</b> ofisimizdeki ekibimiz için <b>Grafik Tasarımcı</b> arıyoruz.</p> \
      <p><b><i>Aranan Özellikler</i></b></p>\
      <ul>\
        <li>En az 3 yıl ajans tecrübesi olan,</li>\
        <li>Üniversitelerin Görsel İletişim Tasarımı veya Grafik Tasarım bölümlerinden mezun,</li>\
        <li>Tercihen motion design bilgisi olan ve bu alanda çalışmış,</li>\
        <li>Adobe programlarına ileri derece hâkim,</li>\
        <li>Geleneksel ve dijital mecralarda konsept yaratabilen,</li>\
        <li>Güncel tasarım trendlerini takip eden ve çalışmalarında uygulayan,</li>\
        <li>Tasarımların baskı matbaa süreçlerine hâkim,</li>\
      </ul>\
      <p><b><i>İş Tanımı</i></b></p>\
      <ul>\
        <li>Marka ve sunum çalışmalarına, görsel tasarım konularında destek vermek,</li>\
        <li>Markalarımızın sosyal medya hesaplarında paylaşılan içeriklerin tasarımlarını ve hareketli içeriklerini üretmek,</li>\
        <li>Basılı çalışmaların ön hazırlıklarını yapmak,</li>\
        <li>Kurumsal kimlik çalışmaları ve uygulamalarını yürütmek,</li>\
        <li>Ekip arkadaşlarının ihtiyaç duyduğu dijital ve basılı görsel materyalleri hazırlamaktan sorumlu olmak</li>\
      </ul>\
      <p>İlgilenen adayların portfolyo ve özgeçmişlerini <i>‘’merhaba@manastir.net’’</i> adresine bekliyoruz.</p> \
    ",
    email: "merhaba@manastir.net",
    location: "Ankara",
    typeOfWork: "Tam Zamanlı",
    experince: "Deneyimli",
    releaseDate: "14/09/2022",
    mailSubject: "Grafik Tasarımcı - İş Başvurusu",
  },
]
