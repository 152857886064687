import React from "react"
import Style from "./JobDetailModal.module.css"
import JobCardStyles from "../JobCard/jobCard.module.css"

const JobDetailModal = ({ onClose, show, job, children }) => {
  const showHideClassName = show ? Style.displayBlock : Style.displayNone
  const mailTo = "mailto:" + job.email + "?subject=" + job.mailSubject
  return (
    <div className={Style.modal + " " + showHideClassName}>
      <div className={Style.modalBody}>
        <div className={Style.modalHeader}>{job.title}</div>
        <div className={JobCardStyles.cardDescription}>
          <div className={JobCardStyles.cardDescriptionItem}>
            <b>Lokasyon: </b>
            {job.location}
          </div>
        </div>
        <div className={Style.modalContent}>{children}</div>
        <div className={Style.modalFooter}>
          <a href={mailTo} className={Style.btn + " " + Style.btnApply}>
            Başvur
          </a>
          <button
            className={Style.btn + " " + Style.btnClose}
            onClick={() => onClose()}
          >
            Kapat
          </button>
        </div>
      </div>
    </div>
  )
}

export default JobDetailModal
