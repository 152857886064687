import React, { useState } from "react"
import JobCardStyles from "./jobCard.module.css"
import JobDetailModal from "../JobDetailModal"
import ICON from "../../images/location.svg"
const JobCard = ({ job }) => {
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <div className={JobCardStyles.card} onClick={() => setModalOpen(true)}>
        <div className={JobCardStyles.cardHeader}>{job.title}</div>
        <div className={JobCardStyles.cardFooter}>
          <img src={ICON} className={JobCardStyles.locationIcon}></img>
          {job.location}
        </div>
      </div>
      {modalOpen && (
        <JobDetailModal
          job={job}
          handleClose={setModalOpen}
          onClose={() => setModalOpen(false)}
          show={modalOpen}
        >
          <div dangerouslySetInnerHTML={{ __html: job.content }}></div>
        </JobDetailModal>
      )}
    </>
  )
}

export default JobCard
